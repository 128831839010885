const DateHelper = {
    dateYYYY_MM_TTtoDE(datestring) {
        if (datestring == null) return "";
        try {
            let parts = datestring.split("-");
            return "" + parts[2] + "." + parts[1] + "." + parts[0];
        } catch (e) {
            return "";
        }
    },

    getYears(startYear) {
        var currentYear = new Date().getFullYear(),
            years = [];
        startYear = startYear || 2016;

        while (startYear <= currentYear) {
            years.push(startYear++);
        }

        return years;
    },

    getInitialMonth() {
        var months = [];

        for (var i = 1; i < 13; i++) {
            months.push(i);
        }

        return months;
    },
    sortDesc(a, b) {
        return b - a;
    }
};

export default DateHelper;
