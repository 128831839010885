<template>
    <div>
        <b-modal id="bv-modal-delete" hide-header hide-footer>
            <div style="text-align: center;">
                <b-icon icon="trash" font-scale="5"></b-icon>
            </div>
            <div class="d-block text-center">
                <h3>Soll der Datensatz wirklich gelöscht werden?</h3>
            </div>
            <b-button
                class="mt-3"
                variant="outline-danger"
                block
                @click="deleteIt(true)"
                >Löschen
            </b-button>
            <b-button
                class="mt-2"
                variant="outline-danger"
                block
                @click="deleteIt(false)"
                >Abbrechen
            </b-button>
        </b-modal>
        <b-modal id="bv-modal-login" hide-footer no-close-on-backdrop>
            <template #modal-title>
                Verwaltung Login
            </template>
            <div class="d-block text-center">
                <b-form>
                    <b-form-group
                        id="input-group-1"
                        label="EMail"
                        label-for="input-1"
                    >
                        <b-form-input
                            id="input-1"
                            v-model="form.email"
                            type="text"
                            required
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group
                        id="input-group-1"
                        label="Password"
                        label-for="input-1"
                    >
                        <b-form-input
                            id="input-1"
                            v-model="form.password"
                            type="password"
                            required
                        ></b-form-input>
                    </b-form-group>
                    <b-button
                        variant="primary"
                        type="button"
                        style="background-color: #dc3545;border-color: #dc3545;"
                        @click="login()"
                    >
                        <b-icon
                            v-if="loading"
                            class="button-loading-icon"
                            icon="three-dots"
                            animation="cylon"
                            font-scale="1"
                        ></b-icon>
                        <span v-if="!loading">Login</span></b-button
                    >
                    <b-form-group>
                        <div class="alert-container">
                            <b-alert v-if="error" show variant="danger"
                                >{{ $t(error) }}
                            </b-alert>
                        </div>
                    </b-form-group>
                </b-form>
            </div>
        </b-modal>

        <b-container v-if="isAuthenticated">
            <h3>Workshop Administration</h3>
            <br />
            <b-row class="mb-2">
                <b-col sm="3" class="text-sm">
                    <b-button
                        @click="addWorkshop()"
                        class="mr-2"
                        variant="outline-danger"
                    >
                        <b-icon icon="plus"></b-icon>
                        Workshop anlegen
                    </b-button>
                </b-col>
                <b-col sm="3" class="text-sm"></b-col>
                <b-col sm="3" class="text-sm"></b-col>
                <b-col sm="3" class="text-sm text-right">
                    <b-button
                        @click="exportExcel()"
                        class="mr-2"
                        variant="outline-danger"
                    >
                        <b-icon
                            v-if="!loadingExcel"
                            icon="file-earmark-excel"
                        ></b-icon>
                        <b-icon
                            v-if="loadingExcel"
                            icon="arrow-counterclockwise"
                            animation="spin-reverse-pulse"
                        ></b-icon>
                        Workshop Excel Export
                    </b-button>
                </b-col>
            </b-row>
            <b-row class="mb-2">
                <b-col sm="4" class="text-sm"></b-col>
                <b-col sm="4" class="text-sm">
                    <b-form-fieldset
                        label-text-align="right"
                        horizontal
                        label="Monat"
                    >
                        <select
                            id="field_month"
                            v-model="monthFilter"
                            class="form-control"
                            style="height: 35px"
                            name="field_month"
                            @change="loadData()"
                        >
                            <option v-for="mm in months" :key="mm" :value="mm"
                                >{{ mm }}
                            </option>
                        </select>
                    </b-form-fieldset>
                </b-col>
                <b-col sm="4" class="text-sm">
                    <b-form-fieldset
                        label-text-align="right"
                        horizontal
                        label="Jahr"
                    >
                        <select
                            id="field_year"
                            v-model="yearFilter"
                            class="form-control"
                            style="height: 35px"
                            name="field_year"
                            @change="loadData()"
                        >
                            <option v-for="yy in years" :key="yy" :value="yy"
                                >{{ yy }}
                            </option>
                        </select>
                    </b-form-fieldset>
                </b-col>
            </b-row>
            <div class="justify-content-center my-1 row"></div>
            <b-table
                striped
                hover
                :items="workshopList"
                :fields="column"
                :filter="criteria"
                :filter-function="filterTable"
            >
                <template #top-row v-if="workshopList.length === 0">
                    <td :colspan="column.length" style="text-align: center">
                        Keine Workshops vorhanden
                    </td>
                </template>
                <template #cell(export_key)="row">
                    <b-button
                        size="sm"
                        @click="exportKeysExcel(row)"
                        class="mr-2"
                        variant="outline-danger"
                    >
                        Key Export
                    </b-button>
                </template>

                <template #cell(show_details)="row">
                    <b-button
                        size="sm"
                        @click="
                            toggleAndSelect(row);
                            checkMedia(row.item);
                        "
                        class="mr-2"
                        variant="outline-danger"
                    >
                        {{
                            row.detailsShowing
                                ? "Details verbergen"
                                : "Details anzeigen"
                        }}
                    </b-button>
                </template>

                <template #cell(show_delete)="row">
                    <b-button
                        size="sm"
                        @click="showDeleteModal('workshop', row)"
                        class="mr-2 btn-danger"
                    >
                        Löschen
                    </b-button>
                </template>

                <template #row-details="row">
                    <b-card>
                        <b-form>
                            <b-row class="mb-2">
                                <b-col sm="3" class="text-sm">
                                    <b-form-group
                                        id="input-group-1"
                                        :label="$t('entity.label.description')"
                                        label-for="input-1"
                                    >
                                        <b-form-input
                                            id="input-1"
                                            v-model="row.item.description"
                                            type="text"
                                            required
                                        ></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="3" class="text-sm">
                                    <b-form-group
                                        id="input-group-1"
                                        :label="$t('entity.label.section')"
                                        label-for="input-1"
                                    >
                                        <b-input-group>
                                            <b-form-select
                                                v-model="row.item.section"
                                                :options="sections"
                                                @change="checkMedia(row.item)"
                                            ></b-form-select>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="1" class="text-sm">
                                    <b-form-group
                                        id="input-group-1"
                                        :label="$t('entity.label.visible')"
                                        label-for="input-1"
                                    >
                                        <b-input-group-prepend>
                                            <b-form-checkbox
                                                switch
                                                class="mr-n2"
                                                v-model="row.item.visible"
                                                value="1"
                                                unchecked-value="0"
                                            >
                                            </b-form-checkbox>
                                        </b-input-group-prepend>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="4" class="text-sm">
                                    <b-form-group
                                        id="input-group-startdate"
                                        :label="$t('entity.label.startdate')"
                                        label-for="input-startdate"
                                    >
                                        <b-input-group>
                                            <b-form-datepicker
                                                id="input-startdate"
                                                v-model="row.item.startdate"
                                                class="mb-2"
                                            ></b-form-datepicker>
                                            <b-input-group-append
                                                class="datepicker-ban"
                                            >
                                                <b-button
                                                    text="Button"
                                                    @click="
                                                        row.item.startdate = null
                                                    "
                                                    ><i class="fa fa-times"></i
                                                ></b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                                <b-col
                                    sm="1"
                                    style="margin-top:auto;margin-bottom: auto"
                                >
                                    <b-button
                                        :disabled="!row.item.id"
                                        title="Duplizieren"
                                        @click="duplicateWorkshop(row)"
                                        ><i class="fa fa-clone"></i
                                    ></b-button>
                                </b-col>
                                <b-col sm="4" class="text-sm">
                                    <b-form-group
                                        id="input-group-1"
                                        :label="
                                            $t('entity.label.visible_until')
                                        "
                                        label-for="input-1"
                                    >
                                        <b-input-group>
                                            <b-form-datepicker
                                                v-model="row.item.valid_until"
                                                class="mb-2"
                                            ></b-form-datepicker>
                                            <b-input-group-append
                                                class="datepicker-ban"
                                            >
                                                <b-button
                                                    text="Button"
                                                    @click="
                                                        row.item.valid_until = null
                                                    "
                                                    ><i class="fa fa-times"></i
                                                ></b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="3" class="text-sm">
                                    <b-form-group
                                        id="input-group-1"
                                        :label="$t('entity.label.timeglobal')"
                                        label-for="input-1"
                                    >
                                        <b-input-group-prepend>
                                            <b-form-checkbox
                                                switch
                                                class="mr-n2"
                                                v-model="row.item.timeglobal"
                                                value="1"
                                                unchecked-value="0"
                                                @change="
                                                    checkTimeState(row.item)
                                                "
                                            >
                                            </b-form-checkbox>
                                        </b-input-group-prepend>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="3" class="text-sm">
                                    <b-form-group
                                        id="input-group-1"
                                        :label="$t('entity.label.time')"
                                        label-for="input-1"
                                    >
                                        <b-input-group>
                                            <b-form-input
                                                :disabled="
                                                    row.item.timeglobal == 1
                                                "
                                                id="input-1"
                                                v-model="row.item.time"
                                                type="text"
                                                placeholder="hh:mm:ss"
                                                required
                                            ></b-form-input>
                                            <b-input-group-append>
                                                <b-form-timepicker
                                                    v-model="row.item.time"
                                                    button-only
                                                    right
                                                    show-seconds
                                                ></b-form-timepicker>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="2" class="text-sm">
                                    <b-form-group
                                        id="input-group-1"
                                        :label="
                                            $t('entity.label.countworkshops')
                                        "
                                        label-for="input-1"
                                    >
                                        <b-form-input
                                            id="input-1"
                                            v-model="row.item.countworkshops"
                                            type="number"
                                            :number="true"
                                            required
                                        ></b-form-input>
                                    </b-form-group>
                                </b-col>

                                <!--<b-col sm="4">
                                    <b-form-group
                                        id="input-group-3"
                                        :label="$t('entity.label.mediaOnly')"
                                        label-for="switch-media"
                                    >
                                        <b-input-group-prepend>
                                            <b-form-checkbox
                                                switch
                                                class="mr-n2"
                                                v-model="row.item.mediaOnly"
                                                :value="1"
                                                unchecked-value="0"
                                            >
                                            </b-form-checkbox>
                                        </b-input-group-prepend>
                                    </b-form-group>
                                </b-col>-->

                                <b-row
                                    class="mb-2"
                                    style="width: 100%;margin-left: 15px;"
                                >
                                    <b-col
                                        sm="3"
                                        v-if="row.item.section == 'Science'"
                                    >
                                        <b-form-group
                                            id="input-group-3"
                                            :label="
                                                $t('entity.label.oberstufe')
                                            "
                                            label-for="switch-media"
                                        >
                                            <b-input-group-prepend>
                                                <b-form-checkbox
                                                    switch
                                                    class="mr-n2"
                                                    v-model="row.item.area1"
                                                    :value="1"
                                                    unchecked-value="0"
                                                    @change="
                                                        checkAreaState(
                                                            row.item,
                                                            true,
                                                            false
                                                        )
                                                    "
                                                >
                                                </b-form-checkbox>
                                            </b-input-group-prepend>
                                        </b-form-group>
                                    </b-col>
                                    <b-col
                                        sm="3"
                                        v-if="row.item.section == 'Science'"
                                    >
                                        <b-form-group
                                            id="input-group-3"
                                            :label="
                                                $t('entity.label.unterstufe')
                                            "
                                            label-for="switch-media"
                                        >
                                            <b-input-group-prepend>
                                                <b-form-checkbox
                                                    switch
                                                    class="mr-n2"
                                                    v-model="row.item.area2"
                                                    :value="1"
                                                    unchecked-value="0"
                                                    @change="
                                                        checkAreaState(
                                                            row.item,
                                                            false,
                                                            false
                                                        )
                                                    "
                                                >
                                                </b-form-checkbox>
                                            </b-input-group-prepend>
                                        </b-form-group>
                                    </b-col>

                                    <!-------CO2-->

                                    <b-col
                                        sm="3"
                                        v-if="row.item.section == 'CO2'"
                                    >
                                        <b-form-group
                                            id="input-group-4"
                                            :label="$t('entity.label.teil1')"
                                            label-for="switch-media"
                                        >
                                            <b-input-group-prepend>
                                                <b-form-checkbox
                                                    switch
                                                    class="mr-n2"
                                                    v-model="row.item.area1"
                                                    :value="1"
                                                    unchecked-value="0"
                                                    @change="
                                                        checkAreaState(
                                                            row.item,
                                                            true,
                                                            false
                                                        )
                                                    "
                                                >
                                                </b-form-checkbox>
                                            </b-input-group-prepend>
                                        </b-form-group>
                                    </b-col>
                                    <b-col
                                        sm="3"
                                        v-if="row.item.section == 'CO2'"
                                    >
                                        <b-form-group
                                            id="input-group-5"
                                            :label="$t('entity.label.teil2')"
                                            label-for="switch-media"
                                        >
                                            <b-input-group-prepend>
                                                <b-form-checkbox
                                                    switch
                                                    class="mr-n2"
                                                    v-model="row.item.area2"
                                                    :value="1"
                                                    unchecked-value="0"
                                                    @change="
                                                        checkAreaState(
                                                            row.item,
                                                            false,
                                                            false
                                                        )
                                                    "
                                                >
                                                </b-form-checkbox>
                                            </b-input-group-prepend>
                                        </b-form-group>
                                    </b-col>

                                    <b-col
                                        sm="3"
                                        v-if="row.item.section == 'CO2'"
                                    >
                                        <b-form-group
                                            id="input-group-6"
                                            :label="$t('entity.label.teil3')"
                                            label-for="switch-media"
                                        >
                                            <b-input-group-prepend>
                                                <b-form-checkbox
                                                    switch
                                                    class="mr-n2"
                                                    v-model="row.item.area3"
                                                    :value="1"
                                                    unchecked-value="0"
                                                    @change="
                                                        checkAreaState(
                                                            row.item,
                                                            false,
                                                            true
                                                        )
                                                    "
                                                >
                                                </b-form-checkbox>
                                            </b-input-group-prepend>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <!--------------------->

                                <b-row
                                    class="mb-2"
                                    style="width: 100%;margin-left: 15px;"
                                >
                                    <b-col sm="3" v-if="mediaMP4Available">
                                        <b-form-group
                                            id="input-group-1"
                                            :label="
                                                $t('entity.label.showVideo')
                                            "
                                            label-for="switch-video"
                                        >
                                            <b-input-group-prepend>
                                                <b-form-checkbox
                                                    switch
                                                    class="mr-n2"
                                                    v-model="row.item.showVideo"
                                                    :value="1"
                                                    unchecked-value="0"
                                                >
                                                </b-form-checkbox>
                                            </b-input-group-prepend>
                                        </b-form-group>
                                    </b-col>
                                    <b-col sm="3" v-if="mediaPDFAvailable">
                                        <div
                                            class="card"
                                            style="padding-left: 5px"
                                        >
                                            <b-form-group
                                                id="input-group-2"
                                                :label="
                                                    $t('entity.label.showPDF')
                                                "
                                                label-for="switch-video"
                                            >
                                                <b-input-group-prepend>
                                                    <b-form-checkbox
                                                        switch
                                                        class="mr-n2"
                                                        v-model="
                                                            row.item.showPdf
                                                        "
                                                        :value="1"
                                                        unchecked-value="0"
                                                    >
                                                    </b-form-checkbox>
                                                </b-input-group-prepend>
                                            </b-form-group>
                                            <b-form-group
                                                id="input-group-2"
                                                :label="
                                                    $t(
                                                        'entity.label.downloadPDF'
                                                    )
                                                "
                                                label-for="switch-video"
                                            >
                                                <b-input-group-prepend>
                                                    <b-form-checkbox
                                                        switch
                                                        class="mr-n2"
                                                        v-model="
                                                            row.item.downloadPdf
                                                        "
                                                        :value="1"
                                                        unchecked-value="0"
                                                    >
                                                    </b-form-checkbox>
                                                </b-input-group-prepend>
                                            </b-form-group>
                                        </div>
                                    </b-col>
                                    <b-col sm="3" v-if="mediaOthersAvailable">
                                        <b-form-group
                                            id="input-group-2"
                                            :label="
                                                $t('entity.label.showOthers')
                                            "
                                            label-for="switch-video"
                                        >
                                            <b-input-group-prepend>
                                                <b-form-checkbox
                                                    switch
                                                    class="mr-n2"
                                                    v-model="
                                                        row.item.showOthers
                                                    "
                                                    :value="1"
                                                    unchecked-value="0"
                                                >
                                                </b-form-checkbox>
                                            </b-input-group-prepend>
                                        </b-form-group>
                                    </b-col>
                                    <b-col sm="3">
                                        <b-form-group
                                            id="input-group-2"
                                            :label="
                                                $t('entity.label.certAnyway')
                                            "
                                            label-for="certAnyway"
                                        >
                                            <b-input-group-prepend>
                                                <b-form-checkbox
                                                    switch
                                                    class="mr-n2"
                                                    v-model="
                                                        row.item.certAnyway
                                                    "
                                                    :value="1"
                                                    unchecked-value="0"
                                                >
                                                </b-form-checkbox>
                                            </b-input-group-prepend>
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <b-col sm="12" class="text-sm">
                                    <b-form-group
                                        id="input-group-1"
                                        :label="$t('entity.label.metainfo')"
                                        label-for="input-1"
                                    >
                                        <vue-editor
                                            id="input-1"
                                            v-model="row.item.metainfo"
                                        ></vue-editor>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <b-row class="mb-2">
                                <b-col class="text-sm">
                                    <b-form-group
                                        id="input-group-1"
                                        :label="$t('entity.label.keys')"
                                        label-for="input-1"
                                    >
                                        <b-button
                                            @click="addKey(row.item)"
                                            class="mr-2 mb-2"
                                            variant="outline-danger"
                                        >
                                            <b-icon icon="plus"></b-icon>
                                            Key anlegen
                                        </b-button>
                                        <b-input-group
                                            class="ml-2 mb-2"
                                            style="float:right; width: 20rem;"
                                        >
                                            <b-form-input
                                                type="number"
                                                v-model="keyGenerateCount"
                                                placeholder="Anzahl"
                                            ></b-form-input>
                                            <b-input-group-append>
                                                <b-button
                                                    :disabled="
                                                        !keyGenerateCount
                                                    "
                                                    @click="
                                                        addMultipleKeys(
                                                            row.item
                                                        )
                                                    "
                                                    variant="outline-danger"
                                                >
                                                    <b-icon
                                                        icon="plus"
                                                    ></b-icon>
                                                    mehrere Keys anlegen
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                        <b-table
                                            striped
                                            hover
                                            :items="row.item.keys"
                                            :fields="keyColumn"
                                        >
                                            <template #cell(show_details)="row">
                                                <b-button
                                                    size="sm"
                                                    @click="row.toggleDetails"
                                                    class="mr-2"
                                                    variant="outline-danger"
                                                >
                                                    {{
                                                        row.detailsShowing
                                                            ? "Details verbergen"
                                                            : "Details anzeigen"
                                                    }}
                                                </b-button>
                                            </template>

                                            <template #cell(show_delete)="row">
                                                <b-button
                                                    size="sm"
                                                    @click="
                                                        showDeleteModal(
                                                            'key',
                                                            row
                                                        )
                                                    "
                                                    class="mr-2 btn-danger"
                                                >
                                                    Löschen
                                                </b-button>
                                            </template>

                                            <template #row-details="key">
                                                <b-card>
                                                    <b-form>
                                                        <b-row class="mb-2">
                                                            <b-col
                                                                sm="3"
                                                                class="text-sm"
                                                            >
                                                                <b-form-group
                                                                    id="input-group-1"
                                                                    :label="
                                                                        $t(
                                                                            'entity.label.key'
                                                                        )
                                                                    "
                                                                    label-for="input-1"
                                                                >
                                                                    <b-input-group>
                                                                        <b-form-input
                                                                            id="input-1"
                                                                            v-model="
                                                                                key
                                                                                    .item
                                                                                    .key
                                                                            "
                                                                            type="text"
                                                                            required
                                                                        ></b-form-input>
                                                                        <b-input-group-append>
                                                                            <b-button
                                                                                size="sm"
                                                                                text="Button"
                                                                                @click="
                                                                                    generateCode(
                                                                                        key.item
                                                                                    )
                                                                                "
                                                                                ><i
                                                                                    class="fa fa-sync fa-lg"
                                                                                ></i
                                                                            ></b-button>
                                                                        </b-input-group-append>
                                                                    </b-input-group>
                                                                </b-form-group>
                                                            </b-col>
                                                            <b-col
                                                                sm="4"
                                                                class="text-sm"
                                                            >
                                                                <b-form-group
                                                                    id="input-group-1"
                                                                    :label="
                                                                        $t(
                                                                            'entity.label.valid_until'
                                                                        )
                                                                    "
                                                                    label-for="keytime"
                                                                >
                                                                    <b-input-group>
                                                                        <b-form-datepicker
                                                                            v-model="
                                                                                key
                                                                                    .item
                                                                                    .valid_until
                                                                            "
                                                                            class="mb-2"
                                                                        ></b-form-datepicker>
                                                                        <b-input-group-append
                                                                            class="datepicker-ban"
                                                                        >
                                                                            <b-button
                                                                                text="Button"
                                                                                @click="
                                                                                    key.item.valid_until = null
                                                                                "
                                                                                ><i
                                                                                    class="fa fa-times"
                                                                                ></i
                                                                            ></b-button>
                                                                        </b-input-group-append>
                                                                    </b-input-group>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                    </b-form>
                                                </b-card>
                                            </template>
                                        </b-table>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <b-row class="mb-2">
                                <b-col class="text-sm">
                                    <b-form-group
                                        id="input-group-1"
                                        :label="$t('entity.label.levels')"
                                        label-for="input-1"
                                    >
                                        <b-button
                                            @click="addLevel(row.item)"
                                            class="mr-2 mb-2"
                                            variant="outline-danger"
                                        >
                                            <b-icon icon="plus"></b-icon>
                                            Level anlegen
                                        </b-button>
                                        <b-table
                                            striped
                                            hover
                                            :items="row.item.levels"
                                            :fields="levelColumn"
                                        >
                                            <template #cell(show_details)="row">
                                                <b-button
                                                    size="sm"
                                                    @click="row.toggleDetails"
                                                    class="mr-2"
                                                    variant="outline-danger"
                                                >
                                                    {{
                                                        row.detailsShowing
                                                            ? "Details verbergen"
                                                            : "Details anzeigen"
                                                    }}
                                                </b-button>
                                            </template>

                                            <template #cell(show_delete)="row">
                                                <b-button
                                                    size="sm"
                                                    @click="
                                                        showDeleteModal(
                                                            'level',
                                                            row
                                                        )
                                                    "
                                                    class="mr-2 btn-danger"
                                                >
                                                    Löschen
                                                </b-button>
                                            </template>

                                            <template #row-details="level">
                                                <b-card>
                                                    <b-form>
                                                        <b-row class="mb-2">
                                                            <b-col
                                                                sm="3"
                                                                class="text-sm"
                                                            >
                                                                <b-form-group
                                                                    id="input-group-1"
                                                                    :label="
                                                                        $t(
                                                                            'entity.label.description'
                                                                        )
                                                                    "
                                                                    label-for="input-1"
                                                                >
                                                                    <b-form-input
                                                                        id="input-1"
                                                                        v-model="
                                                                            level
                                                                                .item
                                                                                .description
                                                                        "
                                                                        type="text"
                                                                        required
                                                                    ></b-form-input>
                                                                </b-form-group>
                                                            </b-col>
                                                            <b-col
                                                                sm="3"
                                                                class="text-sm"
                                                            >
                                                                <b-form-group
                                                                    id="input-group-1"
                                                                    :label="
                                                                        $t(
                                                                            'entity.label.code'
                                                                        )
                                                                    "
                                                                    label-for="input-1"
                                                                >
                                                                    <b-form-input
                                                                        id="input-1"
                                                                        v-model="
                                                                            level
                                                                                .item
                                                                                .code
                                                                        "
                                                                        type="number"
                                                                        :number="
                                                                            true
                                                                        "
                                                                        :formatter="
                                                                            formatter
                                                                        "
                                                                        required
                                                                        @input="
                                                                            codeState(
                                                                                level.item
                                                                            )
                                                                        "
                                                                        :state="
                                                                            level
                                                                                .item
                                                                                .state
                                                                        "
                                                                    ></b-form-input>
                                                                    <b-form-invalid-feedback
                                                                        id="input-live-feedback"
                                                                    >
                                                                        5
                                                                        Zeichen
                                                                    </b-form-invalid-feedback>
                                                                </b-form-group>
                                                            </b-col>

                                                            <b-col
                                                                sm="3"
                                                                class="text-sm"
                                                            >
                                                                <b-form-group
                                                                    id="input-group-1"
                                                                    :label="
                                                                        $t(
                                                                            'entity.label.time'
                                                                        )
                                                                    "
                                                                    label-for="input-1"
                                                                >
                                                                    <b-input-group>
                                                                        <b-form-input
                                                                            :disabled="
                                                                                row
                                                                                    .item
                                                                                    .timeglobal ==
                                                                                    0
                                                                            "
                                                                            id="input-1"
                                                                            v-model="
                                                                                level
                                                                                    .item
                                                                                    .time
                                                                            "
                                                                            type="text"
                                                                            placeholder="hh:mm:ss"
                                                                            required
                                                                        ></b-form-input>
                                                                        <b-input-group-append>
                                                                            <b-form-timepicker
                                                                                v-model="
                                                                                    level
                                                                                        .item
                                                                                        .time
                                                                                "
                                                                                button-only
                                                                                right
                                                                                show-seconds
                                                                            ></b-form-timepicker>
                                                                        </b-input-group-append>
                                                                    </b-input-group>
                                                                </b-form-group>
                                                            </b-col>
                                                            <b-col
                                                                sm="12"
                                                                class="text-sm"
                                                            >
                                                                <b-form-group
                                                                    id="input-group-1"
                                                                    :label="
                                                                        $t(
                                                                            'entity.label.metainfo'
                                                                        )
                                                                    "
                                                                    label-for="input-1"
                                                                >
                                                                    <vue-editor
                                                                        id="input-1"
                                                                        v-model="
                                                                            level
                                                                                .item
                                                                                .metainfo
                                                                        "
                                                                    ></vue-editor>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                    </b-form>
                                                </b-card>
                                            </template>
                                        </b-table>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-form>
                        <div style="float: right;">
                            <b-button
                                style="margin-right: 10px;"
                                size="sm"
                                @click="saveRow(row)"
                                class="btn-success"
                                >Speichern
                            </b-button>
                            <b-button
                                size="sm"
                                @click="cancelRow(row)"
                                class="btn-danger"
                                >Abbrechen
                            </b-button>
                        </div>
                    </b-card>
                </template>
            </b-table>
            <div style="width: 100%;text-align: center">
                <b-icon
                    v-show="loading"
                    style="width: 35%"
                    icon="three-dots"
                    animation="cylon"
                    font-scale="4"
                ></b-icon>
            </div>
        </b-container>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import auth from "../../auth/auth.helpers";
import { VueEditor } from "vue2-editor";
import ApiService from "@/api";
import DateHelper from "@/common/date.helper";
import moment from "moment";

export default {
    name: "Config_list",
    components: {
        VueEditor
    },
    data() {
        return {
            years: DateHelper.getYears(),
            months: DateHelper.getInitialMonth(),
            yearFilter: moment(new Date()).format("YYYY"),
            monthFilter: moment(new Date()).format("M"),
            criteria: "40",
            loading: false,
            loadingExcel: false,
            form: {
                username: "",
                password: ""
            },
            workshopList: [],
            mediaMP4Available: false,
            mediaPDFAvailable: false,
            mediaOthersAvailable: false,
            error: false,
            selectedRow: null,
            loginSuccess: false,
            sections: [
                { value: "CO2", text: "CO2" },
                { value: "BBCmicrobits", text: "BBCmicrobits" },
                { value: "Elektrotechnik", text: "Elektrotechnik" },
                { value: "FabLab", text: "FabLab" },
                { value: "Mathematik", text: "Mathematik" },
                { value: "Ozobot", text: "Ozobot" },
                { value: "Physik I", text: "Physik I" },
                { value: "Physik II", text: "Physik II" },
                { value: "Pocket Code I", text: "Pocket Code I" },
                { value: "Pocket Code II", text: "Pocket Code II" },
                { value: "Safe Surfen", text: "Safe Surfen" },
                { value: "Science", text: "Science" },
                { value: "Chemie", text: "Chemie" }
            ],
            keyColumn: [
                {
                    key: "key",
                    sortable: true,
                    label: this.$t("entity.label.key")
                },
                {
                    key: "valid_until",
                    sortable: true,
                    label: this.$t("entity.label.valid_until")
                },
                {
                    key: "export_key",
                    label: ""
                },
                {
                    key: "show_details",
                    label: ""
                },
                {
                    key: "show_delete",
                    label: ""
                }
            ],
            levelColumn: [
                {
                    key: "description",
                    sortable: true,
                    label: this.$t("entity.label.description")
                },
                {
                    key: "stage",
                    sortable: true,
                    label: this.$t("entity.label.stage")
                },
                {
                    key: "code",
                    sortable: true,
                    label: this.$t("entity.label.code")
                },
                {
                    key: "time",
                    sortable: true,
                    label: this.$t("entity.label.time")
                },
                {
                    key: "export_key",
                    label: ""
                },
                {
                    key: "show_details",
                    label: ""
                },
                {
                    key: "show_delete",
                    label: ""
                }
            ],
            column: [
                {
                    key: "description",
                    sortable: true,
                    label: this.$t("entity.label.description")
                },
                {
                    key: "section",
                    sortable: true,
                    label: this.$t("entity.label.section")
                },
                {
                    key: "levelCount",
                    sortable: true,
                    label: this.$t("entity.label.levels"),
                    tdClass: "center"
                },
                {
                    key: "keyCount",
                    sortable: true,
                    label: this.$t("entity.label.keys"),
                    tdClass: "center"
                },
                {
                    key: "time",
                    sortable: true,
                    label: this.$t("entity.label.time")
                },
                {
                    key: "startdate",
                    sortable: true,
                    label: this.$t("entity.label.startdate"),
                    tdClass: "center"
                },
                {
                    key: "countworkshops",
                    sortable: true,
                    label: this.$t("entity.label.countworkshops"),
                    tdClass: "center"
                },
                {
                    key: "export_key",
                    label: ""
                },
                {
                    key: "show_details",
                    label: ""
                },
                {
                    key: "show_delete",
                    label: ""
                }
            ],
            toDelete: {},
            keyGenerateCount: null
        };
    },
    computed: {
        ...mapGetters({
            workshops: "workshop/list"
        }),
        isAuthenticated() {
            return this.$store.state.auth.isLoggedIn;
        },
        codePerRowState() {
            return this.status;
        }
    },
    mounted() {
        if (!this.isAuthenticated) {
            this.showModal();
        } else {
            this.$store.dispatch("auth/updateAuthorities", ["ROLE_ADMIN"]);
            this.loadData();
        }
    },
    watch: {
        isAuthenticated: function(newVal, oldVal) {
            if (!newVal && oldVal) {
                this.showModal();
            }
        }
    },
    methods: {
        checkMedia(item) {
            if (item.section.replace(/\s/g, "").toLowerCase() == "science") {
                let area = "";
                if (item.area1 == "1") {
                    area = "oberstufe";
                } else {
                    if (item.area2 == "1") {
                        area = "unterstufe";
                    }
                }
                ApiService.get(
                    "workshops/medialist",
                    item.section.replace(/\s/g, "").toLowerCase() + "/" + area
                ).then(val => {
                    this.mediaMP4Available = false;
                    this.mediaPDFAvailable = false;
                    this.mediaOthersAvailable = false;
                    if (val.data.length > 0) {
                        val.data.forEach(x => {
                            if (x.indexOf(".mp4") >= 0) {
                                this.mediaMP4Available = true;
                            }
                            if (x.indexOf(".pdf") >= 0) {
                                this.mediaPDFAvailable = true;
                            }
                            if (
                                x.indexOf(".xls") >= 0 ||
                                x.indexOf(".xlsx") >= 0
                            ) {
                                this.mediaOthersAvailable = true;
                            }
                        });
                    }
                });
            } else {
                if (item.section.replace(/\s/g, "").toLowerCase() == "co2") {
                    let area = "";
                    if (item.area1 == "1") {
                        area = "teil1";
                    } else {
                        if (item.area2 == "1") {
                            area = "teil2";
                        } else {
                            if (item.area3 == "1") {
                                area = "teil3";
                            }
                        }
                    }
                    ApiService.get(
                        "workshops/medialist",
                        item.section.replace(/\s/g, "").toLowerCase() +
                            "/" +
                            area
                    ).then(val => {
                        this.mediaMP4Available = false;
                        this.mediaPDFAvailable = false;
                        this.mediaOthersAvailable = false;
                        if (val.data.length > 0) {
                            val.data.forEach(x => {
                                if (x.indexOf(".mp4") >= 0) {
                                    this.mediaMP4Available = true;
                                }
                                if (x.indexOf(".pdf") >= 0) {
                                    this.mediaPDFAvailable = true;
                                }
                                if (
                                    x.indexOf(".xls") >= 0 ||
                                    x.indexOf(".xlsx") >= 0
                                ) {
                                    this.mediaOthersAvailable = true;
                                }
                            });
                        }
                    });
                } else {
                    ApiService.get(
                        "workshops/medialist",
                        item.section.replace(/\s/g, "").toLowerCase()
                    ).then(val => {
                        this.mediaMP4Available = false;
                        this.mediaPDFAvailable = false;
                        this.mediaOthersAvailable = false;
                        if (val.data.length > 0) {
                            val.data.forEach(x => {
                                if (x.indexOf(".mp4") >= 0) {
                                    this.mediaMP4Available = true;
                                }
                                if (x.indexOf(".pdf") >= 0) {
                                    this.mediaPDFAvailable = true;
                                }
                                if (
                                    x.indexOf(".xls") >= 0 ||
                                    x.indexOf(".xlsx") >= 0
                                ) {
                                    this.mediaOthersAvailable = true;
                                }
                            });
                        }
                    });
                }
            }
        },
        showDeleteModal(typ, row) {
            this.toDelete = {
                object: typ,
                row: row
            };
            this.$bvModal.show("bv-modal-delete");
        },
        deleteIt(flag) {
            if (flag) {
                this.$bvModal.hide("bv-modal-delete");
                switch (this.toDelete.object) {
                    case "workshop":
                        this.deleteRow(this.toDelete.row);
                        break;
                    case "level":
                        this.deleteLevel(this.toDelete.row);
                        break;
                    case "key":
                        this.deleteKey(this.toDelete.row);
                        break;
                    default:
                        this.toDelete = {};
                        this.$bvModal.hide("bv-modal-delete");
                }
            } else {
                this.toDelete = {};
                this.$bvModal.hide("bv-modal-delete");
            }
        },
        codeState(value) {
            return String(value.code).length == 5
                ? (value.state = true)
                : (value.state = false);
        },
        exportExcel() {
            this.loadingExcel = true;
            let params = {
                month: this.monthFilter,
                year: this.yearFilter
            };
            ApiService.getExcel("workshops/export", params).then(val => {
                const link = document.createElement("a");

                // Tell the browser to associate the response data to
                // the URL of the link we created above.
                link.href = window.URL.createObjectURL(new Blob([val.data]));

                // Tell the browser to download, not render, the file.
                link.setAttribute("download", "Export.xlsx");

                // Place the link in the DOM.
                document.body.appendChild(link);

                // Make the magic happen!
                link.click();
                this.loadingExcel = false;
            });
        },
        exportKeysExcel(row) {
            this.loadingExcel = true;
            let params = {
                id: row.item.id
            };
            ApiService.getExcel("workshops/exportKeys", params).then(val => {
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(new Blob([val.data]));
                link.setAttribute("download", "Keys.xlsx");
                document.body.appendChild(link);
                link.click();
                this.loadingExcel = false;
            });
        },
        filterTable(row, filter) {
            if (row.age >= filter) {
                return false;
            } else {
                return true;
            }
        },
        checkTimeState(item) {
            if (item.timeglobal == 1) {
                item.time = null;
            } else {
                item.levels.forEach(x => (x.time = null));
            }
        },
        rndStr(len) {
            let text = "";
            let chars = "abcdefghijklmnopqrstuvwxyz123456789_-";

            for (let i = 0; i < len; i++) {
                text += chars.charAt(Math.floor(Math.random() * chars.length));
            }

            return text;
        },
        generateCode(item) {
            item.key = this.rndStr(5);
            console.log(item.key);
        },
        toggleAndSelect(row) {
            row.toggleDetails();
            this.selectedRow = row.item;
        },
        async loadData() {
            this.workshopList = [];
            this.loading = true;
            let params = {
                month: this.monthFilter,
                year: this.yearFilter
            };
            this.$store
                .dispatch("workshop/query", {
                    params
                })
                .then(val => {
                    val.workshops.forEach(x => {
                        if (x.levels_full.length > 0) {
                            x.levels_full.forEach(level => {
                                if (level.time != null) {
                                    level.time = moment
                                        .utc(level.time * 1000)
                                        .format("HH:mm:ss");
                                }
                            });
                        }

                        let obj = {
                            description: x.description,
                            id: x.id,
                            levels: x.levels_full,
                            levelCount: x.levels_full.length,
                            keys: x.keys,
                            keyCount: x.keys.length,
                            metainfo: x.metainfo,
                            section: x.section,
                            countworkshops: x.countworkshops,
                            time: moment.utc(x.time * 1000).format("HH:mm:ss"),
                            valid_until: x.valid_until,
                            startdate: x.startdate,
                            visible: x.visible != null ? x.visible : 1,
                            showPdf: x.showPdf != null ? x.showPdf : 1,
                            showVideo: x.showVideo != null ? x.showVideo : 1,
                            mediaOnly: x.mediaOnly != null ? x.mediaOnly : 1,
                            showOthers: x.showOthers != null ? x.showOthers : 1,
                            certAnyway: x.certAnyway != null ? x.certAnyway : 1,
                            downloadPdf:
                                x.downloadPdf != null ? x.downloadPdf : 1,
                            area1: x.area1 != null ? x.area1 : 1,
                            area2: x.area2 != null ? x.area2 : 1,
                            area3: x.area3 != null ? x.area3 : 1,
                            timeglobal: x.time != null ? 0 : 1
                        };
                        this.workshopList.push(obj);
                    });
                    this.loading = false;
                });
        },
        checkAreaState(item, typ, typ2) {
            if (typ) {
                if (item.area1 == "1") {
                    item.area2 = "0";
                    item.area3 = "0";
                } else {
                    if (item.area2 == "1") {
                        item.area1 = "0";
                        item.area3 = "0";
                    }
                }
            } else {
                if (!typ && !typ2) {
                    if (item.area2 == "1") {
                        item.area1 = "0";
                        item.area3 = "0";
                    } else {
                        if (item.area1 == "1") {
                            item.area2 = "0";
                            item.area3 = "0";
                        }
                    }
                } else {
                    if (!typ && typ2) {
                        item.area2 = "0";
                    }
                    if (typ2) {
                        console.log("3 pressed");
                        if (item.area3 == "1") {
                            item.area2 = "0";
                            item.area1 = "0";
                        } else {
                            if (item.area2 == "1" || item.area1 == "1") {
                                item.area3 = "0";
                            }
                        }

                        if (item.area2 == "1") {
                            item.area1 = "0";
                            item.area3 = "0";
                        } else {
                            if (item.area1 == "1") {
                                item.area2 = "0";
                                item.area3 = "0";
                            }
                        }
                    }
                }
            }
            this.checkMedia(item);
        },
        deleteKey(row) {
            if (row.item.id) {
                this.$store
                    .dispatch("workshop/deleteKey", "key/" + row.item.id)
                    .then(() => {
                        this.loadData();
                    });
            } else {
                let find = this.workshopList.findIndex(
                    x => x.id == this.selectedRow.id
                );
                let findKey = this.workshopList[find].keys.findIndex(
                    x => x.key == row.item.key
                );

                if (findKey >= 0) {
                    if (
                        this.workshopList[find].keys.length == 1 &&
                        findKey == 0
                    ) {
                        this.workshopList[find].keys = [];
                    } else {
                        this.workshopList[find].keys.splice(findKey, 1);
                    }
                }
            }
        },
        deleteLevel(row) {
            if (row.item.id) {
                this.$store
                    .dispatch("workshop/deleteLevel", "level/" + row.item.id)
                    .then(() => {
                        this.loadData();
                    });
            } else {
                let find = this.workshopList.findIndex(
                    x => x.id == this.selectedRow.id
                );
                let findLevel = this.workshopList[find].levels.findIndex(
                    x => x.stage == row.item.stage
                );

                if (findLevel >= 0) {
                    if (
                        this.workshopList[find].levels.length == 1 &&
                        findLevel == 0
                    ) {
                        this.workshopList[find].levels = [];
                    } else {
                        this.workshopList[find].levels.splice(findLevel, 1);
                    }
                }
            }
        },
        deleteRow(row) {
            if (row.item.id && row.item.id != -1) {
                this.$store
                    .dispatch("workshop/delete", row.item.id)
                    .then(() => {
                        this.loadData();
                    });
            } else {
                let find = this.workshopList.findIndex(x => x == row.item);
                if (find >= 0) {
                    this.workshopList.splice(find, 1);
                }
            }
        },
        addKey(row) {
            let find = this.workshopList.findIndex(x => x.id == row.id);
            if (find >= 0) {
                this.workshopList[find].keys.push({
                    key: "",
                    valid_until: null,
                    workshop_id: this.workshopList[find].id,
                    _showDetails: true
                });
            }
        },
        addMultipleKeys(row) {
            let find = this.workshopList.findIndex(x => x.id == row.id);
            if (find >= 0) {
                for (let i = 0; i < this.keyGenerateCount; i++) {
                    this.workshopList[find].keys.push({
                        key: this.rndStr(5),
                        valid_until: null,
                        workshop_id: this.workshopList[find].id,
                        _showDetails: true
                    });
                }
                this.keyGenerateCount = null;
            }
        },
        addWorkshop() {
            let obj = {
                id: -1,
                description: "",
                levels: [],
                levelCount: 0,
                keys: [],
                keyCount: 0,
                metainfo: "",
                section: "",
                time: null,
                valid_until: null,
                countworkshops: 1,
                visible: 1,
                startdate: moment(new Date())
                    .format("YYYY-MM-DD")
                    .toString(),
                _showDetails: true,
                timeglobal: 0
            };

            this.workshopList.push(obj);
            this.selectedRow = obj;
        },
        duplicateWorkshop(row) {
            row.toggleDetails();
            const copy = JSON.parse(JSON.stringify(row.item));

            delete copy.id;
            copy.description = copy.description + " - Kopie";
            copy.levels.forEach(level => {
                delete level.id;
                delete level.workshop_id;
            });
            //delete copy.keys;
            copy.keys.forEach(key => {
                delete key.id;
                delete key.workshop_id;
            });
            console.log(copy);
            this.workshopList.push(copy);
            this.selectedRow = copy;
            this.saveWorkshop(copy);
        },
        addLevel(row) {
            let find = this.workshopList.findIndex(x => x.id == row.id);
            if (find >= 0) {
                this.workshopList[find].levels.push({
                    code: 0,
                    description: "",
                    metainfo: "",
                    stage: this.workshopList[find].levels.length + 1,
                    time: null,
                    workshop_id: this.workshopList[find].id,
                    _showDetails: true,
                    state: false
                });
            }
        },
        formatter(value) {
            return Number(value);
        },
        cancelRow(row) {
            row._showDetails = false;
            this.loadData();
        },
        saveRow(row) {
            if (row.item.id == -1) {
                delete row.item.id;
            }
            row.toggleDetails;
            if (row.item.timeglobal == 1) {
                row.item.time = null;
            } else {
                row.item.time = moment(row.item.time, "HH:mm:ss").diff(
                    moment().startOf("day"),
                    "seconds"
                );
            }
            if (row.item.keys.length > 0) {
                for (let i = 0; i < row.item.keys.length; i++) {
                    if (row.item.keys[i].key == "") {
                        row.item.keys.splice(i, 1);
                    }
                }
            }
            if (row.item.levels.length > 0) {
                for (let i = 0; i < row.item.levels.length; i++) {
                    if (row.item.levels[i].code == "") {
                        row.item.levels.splice(i, 1);
                    }
                    delete row.item.levels[i].state;
                    if (row.item.timeglobal == 1) {
                        row.item.levels[i].time = moment(
                            row.item.levels[i].time,
                            "HH:mm:ss"
                        ).diff(moment().startOf("day"), "seconds");
                    }
                }
            }
            delete row.item.timeglobal;
            this.$store
                .dispatch("workshop/update", { ...row.item })
                .then(() => {
                    this.loadData();
                });
        },
        saveWorkshop(item) {
            if (item.id == -1) {
                delete item.id;
            }
            this.loading = true;
            if (item.timeglobal == 1) {
                item.time = null;
            } else {
                item.time = moment(item.time, "HH:mm:ss").diff(
                    moment().startOf("day"),
                    "seconds"
                );
            }
            if (item.keys && item.keys.length > 0) {
                for (let i = 0; i < item.keys.length; i++) {
                    if (item.keys[i].key == "") {
                        item.keys.splice(i, 1);
                    }
                }
            }
            if (item.levels.length > 0) {
                for (let i = 0; i < item.levels.length; i++) {
                    if (item.levels[i].code == "") {
                        item.levels.splice(i, 1);
                    }
                    delete item.levels[i].state;
                    if (item.timeglobal == 1) {
                        item.levels[i].time = moment(
                            item.levels[i].time,
                            "HH:mm:ss"
                        ).diff(moment().startOf("day"), "seconds");
                    }
                }
            }
            delete item.timeglobal;
            this.$store.dispatch("workshop/update", { ...item }).then(() => {
                this.loading = true;
                this.loadData();
            });
        },
        changeValue(item, value) {
            console.log(item);
            console.log(value);
        },
        async login() {
            this.loading = true;
            await auth.loginAdmin(this.form, async callback => {
                this.loading = false;
                if (callback !== true) {
                    this.error = callback.message;
                } else {
                    this.$store.dispatch("auth/updateAuthorities", [
                        "ROLE_ADMIN"
                    ]);
                    this.loginSuccess = true;
                    this.hideModal();
                    this.loadData();
                }
            });
        },
        showModal() {
            this.$store.dispatch("auth/updateAuthorities", ["ROLE_USER"]);
            this.$bvModal.show("bv-modal-login");
            this.loading = false;
        },
        hideModal() {
            this.$bvModal.hide("bv-modal-login");
            this.loading = false;
        },
        onRowClicked(item) {
            if (!item) {
                this.$router.push({ name: "User", params: { slug: null } });
            } else {
                this.$router.push({
                    name: "User",
                    params: { slug: item.id }
                });
            }
        },
        translateCol(colName) {
            return this.$i18n.t(".fields." + colName + ".label");
        }
    }
};
</script>

<style lang="scss">
.datepicker-ban.input-group-append {
    display: block;
}
.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    background-color: #dc3545 !important;
    border-color: #dc3545 !important;
}
@media (min-width: 1200px) {
    .container,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
        max-width: 1240px !important;
    }
}

td.center {
    text-align: center;
}
</style>
